<template>
  <Layout>
    <PageHeader :items="items" />
    <!--FILTER START------>
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <!--        <div class="inner mb-2 row">-->
        <!--          &lt;!&ndash; Country List&ndash;&gt;-->
        <!--          <div-->
        <!--            v-if="-->
        <!--              this.$storageData.login_type == 1 ||-->
        <!--                this.$storageData.login_type == 2 ||-->
        <!--                this.$storageData.profile.empTypeID == 11-->
        <!--            "-->
        <!--            class="col-md-2"-->
        <!--            style="width:12%"-->
        <!--          >-->
        <!--            <label>Country </label>-->
        <!--            <multiselect-->
        <!--              v-model="country"-->
        <!--              :options="countryArr"-->
        <!--              :show-labels="false"-->
        <!--              label="country"-->
        <!--              track-by="country"-->
        <!--              @input="getAllCompanies()"-->
        <!--            ></multiselect>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; Country List END&ndash;&gt;-->
        <!--          &lt;!&ndash; Company List&ndash;&gt;-->
        <!--          <div-->
        <!--            v-if="-->
        <!--              this.$storageData.login_type == 1 ||-->
        <!--                this.$storageData.login_type == 2 ||-->
        <!--                this.$storageData.profile.empTypeID == 11-->
        <!--            "-->
        <!--            class="col-md-2"-->
        <!--            style="width:16%"-->
        <!--          >-->
        <!--            <label>Company</label>-->
        <!--            <multiselect-->
        <!--              v-model="companyID"-->
        <!--              :options="companyArr"-->
        <!--              :show-labels="false"-->
        <!--              label="companyName"-->
        <!--              track-by="companyID"-->
        <!--              @input="getRestaurantBrandsByCompanyID()"-->
        <!--            ></multiselect>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; Compnay List END&ndash;&gt;-->
        <!--          &lt;!&ndash; Brand List&ndash;&gt;-->
        <!--          <div-->
        <!--            v-if="-->
        <!--              this.$storageData.login_type == 1 ||-->
        <!--                this.$storageData.login_type == 2 ||-->
        <!--                this.$storageData.profile.empTypeID == 11-->
        <!--            "-->
        <!--            class="col-md-2"-->
        <!--            style="width:16%"-->
        <!--          >-->
        <!--            <label>Restaurant Brand</label>-->
        <!--            <multiselect-->
        <!--              v-model="brandData"-->
        <!--              :options="brandArr"-->
        <!--              :show-labels="false"-->
        <!--              label="name"-->
        <!--              track-by="restID"-->
        <!--              @input="getRestaurantCities()"-->
        <!--            ></multiselect>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; Brand List END&ndash;&gt;-->
        <!--          &lt;!&ndash; Cities List&ndash;&gt;-->
        <!--          <div-->
        <!--            v-if="-->
        <!--              this.$storageData.login_type == 1 ||-->
        <!--                this.$storageData.login_type == 2 ||-->
        <!--                this.$storageData.profile.empTypeID == 11-->
        <!--            "-->
        <!--            class="col-md-2"-->
        <!--            style="width:12%"-->
        <!--          >-->
        <!--            <label>City </label>-->
        <!--            <multiselect-->
        <!--              v-model="city"-->
        <!--              :options="cityArr"-->
        <!--              :show-labels="false"-->
        <!--              label="city"-->
        <!--              track-by="city"-->
        <!--              @input="readRestaurantBranchData()"-->
        <!--            ></multiselect>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;END Cities List&ndash;&gt;-->
        <!--          &lt;!&ndash; Branch List&ndash;&gt;-->
        <!--          <div-->
        <!--            v-if="-->
        <!--              this.$storageData.login_type == 1 ||-->
        <!--                this.$storageData.login_type == 2 ||-->
        <!--                this.$storageData.profile.empTypeID == 11-->
        <!--            "-->
        <!--            class="col-md-2"-->
        <!--            style="width:16%"-->
        <!--          >-->
        <!--            <label>Restaurant Branch </label>-->
        <!--            <multiselect-->
        <!--              v-model="branchID"-->
        <!--              :options="branches"-->
        <!--              :show-labels="false"-->
        <!--              label="branchName"-->
        <!--              track-by="restBranchID"-->
        <!--              @input="onchangeBranch()"-->
        <!--            ></multiselect>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; Branch List END&ndash;&gt;-->
        <!--          &lt;!&ndash; Date range &ndash;&gt;-->
        <!--          <div class="col-md-2" style="width:20%">-->
        <!--            <label>Date Range </label>-->
        <!--            <date-picker-->
        <!--              v-model="daterange"-->
        <!--              append-to-body-->
        <!--              confirm-->
        <!--              format="DD MMM Y"-->
        <!--              lang="en"-->
        <!--              range-->
        <!--              @input="onchangeBranch()"-->
        <!--            ></date-picker>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; Date range END&ndash;&gt;-->
        <!--          &lt;!&ndash; Order Type &ndash;&gt;-->
        <!--          &lt;!&ndash; <div class="col-md-2"  style="width:18%">   -->
        <!--                            <label>Order Type </label>-->
        <!--                            <multiselect v-model="orderType" :options="orderTypeArr" @input="onchangeBranch();"></multiselect>-->
        <!--                </div> &ndash;&gt;-->
        <!--          &lt;!&ndash; Order Type END&ndash;&gt;-->

        <!--          <div class="col-md-2" style="width:auto">-->
        <!--            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">-->
        <!--              Apply Filter-->
        <!--            </button>-->
        <!--          </div>-->

        <!--          &lt;!&ndash; Clear filter &ndash;&gt;-->
        <!--          <div class="col-md-2" style="width:16%">-->
        <!--            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">-->
        <!--              Clear Filter-->
        <!--            </button>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; Clear filter END&ndash;&gt;-->
        <!--        </div>-->
        <div class="row">
          <div class="col-md-2">
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getAllCities"
            ></multiselect>
          </div>
          <div class="col-md-2">
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getPrincipleAndCorporates()"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <label>Select Principle Branch </label>
            <multiselect
              v-model="principleBranchID"
              :options="principleBranches"
              :show-labels="false"
              label="principleBranchName"
              track-by="principleBranchID"
              @input="getRestaurantByPrincipleBranch"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <label class="form-label">Select Corporate Branch </label>
            <multiselect
              v-model="corpBranchID"
              :options="corpBranches"
              :show-labels="false"
              label="corpBranchName"
              track-by="corpBranchID"
              @input="getRestaurantByCorporateBranch"
            ></multiselect>
            <!-- @input="onchangeBranch();" -->
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <label class="form-label" for="form row-endTime-input"
              >Select Restaurant Branch*</label
            >
            <multiselect
              v-model="restBranchID"
              :class="{
                'is-invalid': submitted && $v.restBranchID.$error
              }"
              :disabled="isEdit"
              :options="restBranchArr"
              label="restaurantName"
              track-by="restBranchID"
              @input="
                getFdbacktemplatebyrestbranch(restBranchID.restBranchID),
                  getReasonGroups()
              "
            ></multiselect>
            <div
              v-if="submitted && $v.restBranchID.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.restBranchID.required"
                >This value is required.</span
              >
            </div>
          </div>
          <div class="col-md-2" style="width:auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <div class="col-md-2" style="width:16%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row mt-4">
                  <!-- <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown">Excel</button> 
                       <button type="button" class="btn btn-themeBrown">Column Visibility</button>
                    </div>
               </div> -->
                  <div class="col-sm-12 col-md-4">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show &nbsp;&nbsp;
                        <b-form-select
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                          style="margin-left:5px; margin-right:5px"
                        ></b-form-select
                        >&nbsp;&nbsp;&nbsp; entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-4 row">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-end"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          class="form-control form-control-sm ms-2"
                          placeholder="Search..."
                          type="search"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :current-page="currentPage"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="tableData"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    bordered
                    hover
                    outlined
                    responsive
                    striped
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(Action)="data">
                      <router-link
                        v-if="editRight == 1"
                        :to="{
                          name: 'feedbackForm',
                          params: { id: data.item.fdtempId, type: 'edit' }
                        }"
                      >
                        <i
                          class="uil uil-edit-alt"
                          style="font-size: 19px;"
                          title="Edit"
                        ></i
                      ></router-link>
                      <!--                      <i-->
                      <!--                        v-if="deleteRight == 1"-->
                      <!--                        class="mdi mdi-delete"-->
                      <!--                        style="font-size: 19px;cursor: pointer;"-->
                      <!--                        title="Delete"-->
                      <!--                        @click="deleteTemplate(data.item.optionId)"-->
                      <!--                      ></i>-->
                    </template>

                    <template v-slot:cell(question)="data">
                      <span>{{ data.item.fdbackQuestion }}</span>
                    </template>
                    <template v-slot:cell(weightage)="data">
                      <span>{{ data.item.weightage }}</span>
                    </template>
                    <template v-slot:cell(reason_group)="data">
                      <span>{{ data.item.reason_group }}</span>
                    </template>
                    <template v-slot:cell(dates)="data">
                      <span>{{ data.item.created_at }}</span>
                    </template>

                    <!-- <template  v-slot:cell(AllQuestions)="data">
                     <span v-for="item in data.item.allQuesAnsw" :key="item.allQuesAnsw">{{item.fdbackQuestion}} <br/> </span>
                 </template>

                   <template  v-slot:cell(QuestionType)="data" >
                    <span v-for="item in data.item.allQuesAnsw" :key="item.allQuesAnsw">{{item.typeofQuestion}} <br/>  </span>

                   </template> -->
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :per-page="perPage"
                          :total-rows="rows"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal id="modal-lg-repliedDetails"   size="lg" centered><div class="container deliveryAddContainer">
                    <input class="form-control" type="text"  v-model="replyField" placeholder="Type Your Reply"> 
                   <h4><u>Review :</u></h4> {{this.reviewText}}<br><hr>
                   <h4><u>Reply And Replied by :</u></h4>
                    <div class="row" v-for="item in this.allreliesreplier" :key="item.message">
                      <div class="col-lg-8"> {{item.replyText }} </div>
                      <div class="col-lg-4" v-if="item.adminReply!=null"> {{item.adminReply}} </div>
                      <div class="col-lg-4" v-if="item.userReply!=null"> {{item.userReply}} </div>
                    </div>
                     </div>
        </b-modal>    -->
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Feedback Template",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      tableData: [],
      tableData2: [],
      title: "Feedback Template",
      items: [
        {
          text: "View"
        },
        {
          text: "Feedback Template",
          active: true
        }
      ],
      restaurants: [],
      branches: [],
      cityArr: [],
      city: "",
      allFdbackTemplate: [],
      daterange: [],
      countryArr: [],
      orderData: [],
      orderDetails: [],
      country: "",
      brandArr: [],
      brandData: "",
      restBranchID: this.$storageData.profile.restBranchID,
      reviewText: "",
      restaurantID: "",
      branchID: "",
      companyArr: [],
      companyID: 0,
      sortBy: "age",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      allreliesreplier: [],
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false
        },
        {
          key: "question",
          label: "Question",
          sortable: true
        },
        {
          key: "reason_group",
          label: "Reason Group",
          sortable: true
        },
        {
          key: "weightage",
          label: "Weightage",
          sortable: true
        },
        {
          key: "dates",
          sortable: true
        }
      ],
      ////////// datatable 3 category //////////////////
      // datatable 1 & 2 ///////
      sortBy2: "age",
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter2: null,
      filterOn2: [],
      sortDesc2: false,
      path: "",
      editRight: "",
      deleteRight: "",
      principleBranchID: "",
      principleBranches: [],
      corpBranchID: "",
      corpBranches: [],
      restBranchArr: []
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.readRestaurantMenu();
    // this.getRestaurantList();

    this.getAllCountry();

    // else{
    //      this.getFeedbaackTemplate();
    // }

    // this.getFeedbaackTemplate();
    this.checkUserRights();
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkUserRights() {
      this.path = this.$route.path;
      this.axios
        .post(this.$loggedRole+"/checkUserRights", {
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          path: this.path
        })
        .then(result => {
          this.editRight = result.data.data[0].isEdit;
          this.deleteRight = result.data.data[0].isDelete;
        });
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getAllCities() {
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
        });
    },
    getPrincipleAndCorporates() {
      this.getPrincipleBranches();
      this.getCorporateBranches();
    },
    getPrincipleBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/get-corporate-branches", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.corpBranches = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getRestaurantByPrincipleBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/principle/restaurants", {
          city: this.city ? this.city.city : "",
          principleBranchID: this.principleBranchID
            ? this.principleBranchID.principleBranchID
            : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    getRestaurantByCorporateBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/corporate/restaurants", {
          city: this.city ? this.city.city : "",
          corpBranchID: this.corpBranchID ? this.corpBranchID.corpBranchID : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },

    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID
        })
        .then(result => {
          this.brandArr = result.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", { companyID: companyID })
        .then(response => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", { restID: this.$storageData.profile.restID })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getAllCompanies();
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      var restID = this.brandData.restID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        restID = this.$storageData.profile.restID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID: restID,
          country: this.country ? this.country.country : ""
        })
        .then(response => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          if (
            this.$storageData.login_type == 2 ||
            this.$storageData.profile.empTypeID == 11
          ) {
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", { companyID: companyID })
        .then(response => {
          this.companyArr = response.data.data;
          if (
            this.$storageData.login_type == 2 ||
            this.$storageData.profile.empTypeID == 11
          ) {
            this.companyID = this.companyArr ? this.companyArr[0] : "";
          }
          //  this.branchID = (this.branches) ? this.branches[0] :"";
          //  this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          //  this.getFeedbaackTemplate();
        });
    },
    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          // this.getFeedbaackTemplate();
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();
      if (this.restaurantID.restID > 0) {
        /// for maintaining state of dropdown
        sessionStorage.setItem("restID", this.restaurantID.restID);
        sessionStorage.setItem("restName", this.restaurantID.name);
      }
      if (this.branchID.restBranchID > 0) {
        /// for maintaining state of dropdown
        sessionStorage.setItem("branchID", this.branchID.restBranchID);
        sessionStorage.setItem("branchName", this.branchID.branchName);
      }
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      //this.getFeedbaackTemplate();
    },

    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.getFeedbaackTemplate();
      // this.getProductMixReportByValue(this.productMixTab);
      // this.getProductMixReportByCategory();
    },

    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.paymentType = "";
      this.orderType = "";
      this.principleBranchID = "";
      this.corpBranchID = "";
    },

    getFeedbaackTemplate() {
      if (this.principleBranchID.length <= 0 && this.corpBranchID.length <= 0) {
        alert("Please select either principle branch or corporate branch.");
        return;
      } else if (
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        alert(
          "You can create reason groups for either principle branch or corporate branch."
        );
        return;
      } else {
        this.axios
          .post(this.$loggedRole+"/feedback-template/get-feedback-templates", {
            restaurant_branch_id: this.restBranchID
              ? this.restBranchID.restBranchID
              : "",
            empTypeID: this.$storageData.profile.empTypeID,
            principle_branch_id: this.principleBranchID
              ? this.principleBranchID.principleBranchID
              : "",
            corporate_branch_id: this.corpBranchID
              ? this.corpBranchID.corpBranchID
              : ""
          })
          .then(result => {
            this.tableData = result.data.data;
          })
          .catch(error => {
            alert(error.response.data.message);
          });
      }
    },
    // applyFilter(){
    //   this.readRestaurantMenu();
    // }

    //  viewSalesReport(){

    //       this.axios
    //     .post(
    //       "viewSalesReportRating",
    //       {
    //         restID : (this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
    //         restBranchID : this.restBranchID,
    //         daterange : this.daterange,
    //         paymentType : this.paymentType,
    //         orderType : this.orderType,
    //       }
    //     )
    //     .then((response) => {
    //       //Then injecting the result to datatable parameters.

    //        this.tableData = response.data.data;
    //        this.cardData = response.data.cardData;
    //       //  this.salesReportGraph();
    //        //console.log((response));

    //     });
    //   },

    //  seeDetailsReply(text,fdid){

    //     this.axios.post(
    //       "getRepliesFeedback", {'feedbackId':fdid}//this.branchID.restBranchID}
    //     )
    //     .then((response) => {
    //        this.allreliesreplier = response.data.data;
    //       //  console.log((response));
    //     });

    //     this.reviewText=text;
    //   //  this.repliedBy=repliedby;

    //     this.$root.$emit("bv::show::modal", "modal-lg-repliedDetails");

    // },

    getFeedbaackTemplateByValue(flag) {
      this.axios
        .post(this.$loggedRole+"/getFeedbackTemplates",
          {
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID: this.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            daterange: this.daterange,
            flag: flag
          } //this.branchID.restBranchID}
        )
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },
    getFeedbaackTemplateByCategory() {
      this.axios
        .post(this.$loggedRole+"/getFeedbackTemplates",
          {
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID: this.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            daterange: this.daterange
          } //this.branchID.restBranchID}
        )
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData2 = response.data.data;
          //console.log((response));
        });
    }

    // deleteTemplate(feedbackTempID) {
    //   if (feedbackTempID > 0) {
    //     this.loading = true;
    //     if (confirm("Are you sure you want to delete this Question?")) {
    //       this.axios
    //         .post(this.$loggedRole+"/feedback-template/remove-question", {
    //           optionId: feedbackTempID
    //         })
    //         .then(result => {
    //           this.loading = false;
    //           alert(result.data.message);
    //           setTimeout(() => {
    //             location.reload();
    //           }, 2000);
    //         })
    //         .catch(error => {
    //           this.loading = false;
    //           alert(error.response.data.data);
    //         });
    //     }
    //   }
    // }
  },
  middleware: "authentication"
};
</script>
<style lang="scss" scoped>
/** or use this class: table thead th.bTableThStyle { ... } */
.table .bTableThStyle {
  max-width: 500px !important;
  text-overflow: ellipsis !important;
  background: #000;
}
</style>
